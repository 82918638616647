import { SubmitButton } from 'components/access-code/pages/home/Introduction/styles';
import React from 'react';

const AccessCodeSubmit = ({ ...props }) => (
  <SubmitButton {...props}>
    Order your test
  </SubmitButton>
);

export default AccessCodeSubmit;
