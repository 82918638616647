import React from 'react';

import { CodeInput } from '../../access-code/pages/home/Introduction/styles';

interface Props {
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  error: string;
}

const AccessCodeField = ({ value, onChange, error }: Props) => (
  <CodeInput
    value={value}
    onChange={onChange}
    error={error}
    id="accessCode"
    name="accessCode"
    label="Your organization invitation code"
    placeholder="Enter code"
  />
);

export default AccessCodeField;
