import { Button } from '@everlywell/leaves';
import React from 'react';
import { HUMANA_STATIC_CONTENT } from 'utils/constants';
import { analytics } from 'utils/helpers';
import Cookies from 'js-cookie';

import * as S from './styles';

const handleClick = () => {
  Cookies.set('thirdparty', 'humana', {
    path: '/',
    domain: 'everlywell.com',
    secure: true,
  });

  analytics.track('CTA Clicked', {
    text: 'View test Results',
  });
};

const DigitalResultsSection = () => {
  return (
    <S.DigitalResultsSection>
      <S.DigitalResultsContainer>
        <p>Ready to review your results?</p>
        <div>
          <Button
            href={HUMANA_STATIC_CONTENT.EW_RESULTS_URL}
            appearance="secondary"
            onClick={handleClick}
          >
            View test results
          </Button>
        </div>
      </S.DigitalResultsContainer>
    </S.DigitalResultsSection>
  );
};

export default DigitalResultsSection;
