import React from 'react';
import { HUMANA_STATIC_CONTENT } from 'utils/constants';

import * as S from './styles';

const PartnerWelcomeSection = () => {
  return (
    <S.PartnerWelcomeContainer>
      <S.PartnerWelcomeContent>
        <h2>{HUMANA_STATIC_CONTENT.LANDING_PAGE_HEADLINE}</h2>
        <p>{HUMANA_STATIC_CONTENT.LANDING_PAGE_BASIC_DETAILS}</p>
        <p>{HUMANA_STATIC_CONTENT.LANDING_PAGE_MORE_DETAILS}</p>
        <p>{HUMANA_STATIC_CONTENT.LANDING_PAGE_TEST_NOT_ORDERED}</p>
      </S.PartnerWelcomeContent>
      <S.OrderDetailsWrapper>
        <div>
          <S.ContactInformation>
            <p>Questions? Call Everlywell</p>
            <span>
              <a href={`tel:${HUMANA_STATIC_CONTENT.EVERLYWELL_PHONE_NUMBER.replace(/-/g, '')}`}>
                {HUMANA_STATIC_CONTENT.EVERLYWELL_PHONE_NUMBER}
              </a>
              <span>{HUMANA_STATIC_CONTENT.EVERLYWELL_PHONE_NUMBER_MODE}</span>
            </span>
          </S.ContactInformation>
          <S.Availability>
            Available Monday - Friday{' '}
            <span>{HUMANA_STATIC_CONTENT.EVERLYWELL_AVAILABILITY}</span>
          </S.Availability>
        </div>
      </S.OrderDetailsWrapper>
    </S.PartnerWelcomeContainer>
  );
};

export default PartnerWelcomeSection;
