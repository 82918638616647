import {
  colors,
  size,
  breakpoints,
  Container,
} from '@everlywell/leaves';
import styled from 'styled-components';

const APPEARANCE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  HERO: 'hero',
  TERTIARY: 'tertiary',
};

const HeroSection = styled.header`
  position: relative;
  background-color: ${colors.teal1};
`;

const HeroSectionContainer = styled.div <{ bg: string; mobileBg: string; }>`
  background-image: url(${(props) => props.mobileBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 439px;
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.forTabletVerticalUp}px) {
    background-image: url(${(props) => props.bg});
    min-height: 379px;
    padding: 32px 40px;
  }

  @media (min-width: ${breakpoints.forTabletHorizontalUp}px) {
    padding: 40px;
    min-height: 493px;
  }

  @media (min-width: ${breakpoints.forDesktopUp}px) {
    padding: 48px 64px;
    min-height: 600px;
  }

  h1 {
    padding: 30px 0 0 0;
    font-size: 36.5px;
    line-height: 1.21;
    letter-spacing: -0.6px;
    color: ${colors.teal4};
    font-weight: 600;
    text-align: center;
    margin: 0;

    @media (min-width: ${breakpoints.forTabletVerticalUp}px) {
        text-align: left;
        width: 310px;
    }

    @media (min-width: ${breakpoints.forTabletHorizontalUp}px) {
      padding: 40px 0 0 0;
      font-size: 52px;
      line-height: 1.23;
      letter-spacing: -0.86px;
      width: 473px;
    }

    @media (min-width: ${breakpoints.forDesktopUp}px) {
      width: 513px;
      margin: auto 0;
      padding: 0;
    }
  }
`;

const HeroNav = styled.nav`
  display: block;
  text-align: center;

  @media (min-width: ${breakpoints.forTabletVerticalUp}px) {
    text-align: left;
  }
`;

const PartnerWelcomeContainer = styled.div`
  margin: 48px 0;
  padding: 0 24px;

  @media (min-width: ${breakpoints.forTabletVerticalUp}px) {
    padding: 0 72px;
  }

  @media (min-width: ${breakpoints.forTabletHorizontalUp}px) {
    margin: 61px auto 48px;
    max-width: 712px;
    padding: 0;
  }

  @media (min-width: ${breakpoints.forDesktopUp}px) {
    margin: 96px auto;
    max-width: 866px;
  }
`;

const PartnerWelcomeContent = styled.div`
  margin-bottom: 48px;

  @media (min-width: ${breakpoints.forTabletVerticalUp}px) {
    margin-bottom: 40px;
  }

  @media (min-width: ${breakpoints.forTabletHorizontalUp}px) {
    margin-bottom: 0;
  }

  h2 {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.27px;
    color: ${colors.gray5};
    font-weight: 500;
    padding-bottom: 24px;

    @media (min-width: ${breakpoints.forTabletHorizontalUp}px) {
      font-size: 20.3px;
      line-height: 1.98;
      letter-spacing: 0.55px;
      padding-bottom: 36px;
      margin: 0;
    }
  }

  p {
    margin: 0;
    font-size: 16px;
    line-height: 1.75;
    letter-spacing: normal;
    font-weight: 400;
    color: ${colors.gray4};

    @media (min-width: ${breakpoints.forTabletHorizontalUp}px) {
      font-size: 20.3px;
      line-height: 1.98;
      letter-spacing: 0.55px;
      padding-bottom: 36px;
    }
  }
`;

const OrderDetailsWrapper = styled.div`
  text-align: center;
  width: 100%;
`;

const ContactInformation = styled.div`
  text-align: center;

  @media (min-width: ${breakpoints.forTabletVerticalUp}px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  p {
    margin: 0 0 8px 0;
    text-align: center;
    font-size: 18px;
    line-height: 1.78;
    letter-spacing: normal;
    font-weight: 400;
    color: ${colors.gray4};

    @media (min-width: ${breakpoints.forTabletVerticalUp}px) {
      margin: 0;
    }

    @media (min-width: ${breakpoints.forTabletHorizontalUp}px) {
      font-size: 22.8px;
      line-height: 1.76;
      letter-spacing: normal;
    }
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      font-size: 18px;
      line-height: 1.33;
      letter-spacing: 0.3px;
      font-weight: 600;
      color: ${colors.green4};
      text-decoration: none;

      @media (min-width: ${breakpoints.forTabletVerticalUp}px) {
        margin: 0 8px;
      }

      @media (min-width: ${breakpoints.forTabletHorizontalUp}px) {
        font-size: 22.8px;
        line-height: 1.76;
        letter-spacing: normal;
        font-weight: 400;
      }
    }

    span {
      font-size: 18px;
      line-height: 1.78;
      letter-spacing: normal;
      font-weight: 400;
      color: ${colors.gray4};

      @media (min-width: ${breakpoints.forTabletHorizontalUp}px) {
        font-size: 22.8px;
        line-height: 1.76;
        letter-spacing: normal;
      }
    }
  }
`;

const Availability = styled.div`
  padding: 8px 0 0 0;
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: normal;
  font-weight: 400;
  color: ${colors.gray4};

  @media (min-width: ${breakpoints.forTabletVerticalUp}px) {
    padding-top: 10px;
  }

  @media (min-width: ${breakpoints.forTabletHorizontalUp}px) {
    font-size: 18px;
    line-height: 1.78;
  }

  span {
    display: block;

    @media (min-width: ${breakpoints.forTabletVerticalUp}px) {
      display: inline-block;
    }
  }
`;

const DigitalResultsSection = styled.section`
  background-image: linear-gradient(to bottom,${colors.teal1} -20%,${colors.white} 80%);
  padding: 0 24px 40px;
  margin-bottom: 48px;

  @media (min-width: ${breakpoints.forTabletVerticalUp}px) {
    background-image: linear-gradient(to bottom, ${colors.teal1}, ${colors.white});
    padding-bottom: 48px;
  }

  @media (min-width: ${breakpoints.forTabletHorizontalUp}px) {
    background-image: linear-gradient(to bottom, ${colors.teal1} -20%, ${colors.white} 80%);
    padding: 40px 0;
  }

  @media (min-width: ${breakpoints.forDesktopUp}px) {
    padding: 65px 0;
  }
`;

const DigitalResultsContainer = styled.div`
  padding-top: ${size.xl3}px;
  text-align: center;

  @media (min-width: ${breakpoints.forTabletHorizontalUp}px) {
    padding-top: 0;

    &:nth-last-child(1) {
      padding-top: 48px;
    }
  }

  p {
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: 0.45px;
    color: ${colors.gray5};
    font-weight: 500;
    padding-bottom: ${size.lg}px;
    margin: 0;

    @media (min-width: ${breakpoints.forTabletVerticalUp}px) {
      padding-bottom: 18px;
    }

    @media (min-width: ${breakpoints.forTabletHorizontalUp}px) {
      padding-bottom: 16px;
    }
  }

  a {
    display: block;
    margin: 0 auto;
    background-color: transparent;
    width: 220px;
    line-height: 1.33;
    letter-spacing: .3px;
    padding: 12px 32px;

    @media (min-width: ${breakpoints.forDesktopUp}px) {
      width: 300px;
      font-size: 22.8px;
      line-height: 1.76;
      letter-spacing: .08px;
      padding: 12px 40px;
    }
  }

  h4 {
    max-width: 300px;
    letter-spacing: normal;
    font-size: 25px;
  }
`;

const HowItWorksSection = styled.div`
  padding: 0 ${size.lg}px;
  text-align: center;
  margin-bottom: ${size.xl3}px;

  @media (min-width: ${breakpoints.forDesktopUp}px) {
    max-width: 1102px;
    width: 80%;
    margin: 0 auto 32px;
  }
`;

const UppercaseHeading = styled.h2`
  font-size: 14.2px;
  line-height: 1.13;
  letter-spacing: 3.16px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${colors.green4};
  margin-bottom: 16px;

  @media (min-width: ${breakpoints.forTabletVerticalUp}px) {
    margin-bottom: 21px;
  }
`;

const HowItWorksMainHeading = styled.p`
  font-size: 25.6px;
  line-height: 1.25;
  letter-spacing: 0.53px;
  font-weight: 600;
  color: ${colors.teal4};
  text-align: center;
  margin: 0;

  @media (min-width: ${breakpoints.forTabletHorizontalUp}px) {
    font-size: 32.4px;
    line-height: 1.23;
    letter-spacing: 0.56;
  }
`;

const HowItWorksListContainer = styled.div`
  margin-top: 48px;

  @media (min-width: ${breakpoints.forTabletVerticalUp}px) {
    margin-top: 100px;
  }

  @media (min-width: ${breakpoints.forTabletHorizontalUp}px) {
    margin-top: 64px;
  }

  @media (min-width: ${breakpoints.forDesktopUp}px) {
    padding: 0 48px;
  }
`

const HowItWorksList = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  row-gap: 20px;
  margin-bottom: 24px;

  @media (min-width: ${breakpoints.forTabletVerticalUp}px) {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 16px;
    row-gap: 48px;
    margin-bottom: 64px;
  }

  @media (min-width: ${breakpoints.forTabletHorizontalUp}px) {
    row-gap: 64px;
  }
`;

const HowItWorksItem = styled.li`
  div {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;

    img {
      width: auto;
      vertical-align: middle;
      border-style: none;
    }
  }

  p {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.27px;
    text-align: center;
    font-weight: 500;
    color: ${colors.gray5};
    margin: 0;

    @media (min-width: ${breakpoints.forTabletVerticalUp}px) {
      font-size: 18px;
      line-height: 1.33;
      letter-spacing: 0.45px;
    }

    @media (min-width: ${breakpoints.forDesktopUp}px) {
      max-width: 220px;
      margin: 0 auto;
    }
  }
`;

export {
  HeroSection,
  HeroSectionContainer,
  HeroNav,
  PartnerWelcomeContainer,
  PartnerWelcomeContent,
  OrderDetailsWrapper,
  ContactInformation,
  Availability,
  DigitalResultsSection,
  DigitalResultsContainer,
  HowItWorksSection,
  UppercaseHeading,
  HowItWorksMainHeading,
  HowItWorksListContainer,
  HowItWorksList,
  HowItWorksItem,
};
