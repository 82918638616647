import React from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';

import AnalyticsPageMeta from '../../../common/AnalyticsPageMeta';
import { Layout } from '../../common';
import DigitalResultsSection from './DigitalResultsSection';
import HeroSection from './HeroSection';
import PartnerWelcomeSection from './PartnerWelcomeSection';
import HowItWorksSection from './HowItWorksSection';


interface Props {
  heroText: string;
  heroBg: {
    gatsbyImageData: IGatsbyImageData
  };
  heroMobileBg: {
    gatsbyImageData: IGatsbyImageData
  };
  partnerName: string;
  customerLogo: string;
  footerInfo: string;
}

const Home = ({
  heroText,
  heroBg,
  heroMobileBg,
  partnerName,
  customerLogo,
  footerInfo,
}: Props) => {
  return (
    <Layout
      theme={{ pageBackground: '#fff' }}
      customerLogo={customerLogo}
      partnerName={partnerName}
      footerInfo={footerInfo}
      isLandingPage
    >
      <AnalyticsPageMeta pageName={'home'} />
      <HeroSection
        partnerName={partnerName}
        text={heroText}
        bg={heroBg?.gatsbyImageData?.images?.fallback?.src ?? ''}
        mobileBg={heroMobileBg?.gatsbyImageData?.images?.fallback?.src ?? ''}
        customerLogo={customerLogo}
      />
      <PartnerWelcomeSection />
      <DigitalResultsSection />
      <HowItWorksSection />
    </Layout>
  );
};

export default Home;
