import { H3, Button } from '@everlywell/leaves';
import { Link } from 'gatsby';
import partnerWelcomeImage from 'assets/images/partner-welcome.png';
import React from 'react';
import { analytics } from 'utils/helpers';

import * as S from './styles';

interface Props {
  heading: string;
  body: string;
}

const PartnerWelcomeSection = ({ heading, body }: Props) => {
  return (
    <S.PartnerWelcomeContainer tagName="section">
      <img src={partnerWelcomeImage} alt="Everlywell - Lab Test at Home" />
      <S.PartnerWelcomeContent>
        <H3>{heading}</H3>
        <p>{body}</p>
        <Button
          // TODO: Remove @ts-ignore after updating Leaves Button component typying.
          // @ts-ignore
          component={Link}
          to="/order"
          appearance="primary"
          onClick={() => {
            analytics.track('CTA Clicked', {
              text: 'Claim Your Tests',
            });
          }}
        >
          Claim your tests
        </Button>
      </S.PartnerWelcomeContent>
    </S.PartnerWelcomeContainer>
  );
};

export default PartnerWelcomeSection;
