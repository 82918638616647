import {
  colors,
  size,
  mediaQueries,
  breakpoints,
  Container,
  H3,
  H2,
  Button,
} from '@everlywell/leaves';
import {
  PrimaryStyle,
  SecondaryStyle,
  HeroStyle,
  TertiaryStyle,
} from '@everlywell/leaves/lib/components/Button/styles';
import styled from 'styled-components';

const APPEARANCE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  HERO: 'hero',
  TERTIARY: 'tertiary',
};

const HeroSection = styled.header`
  position: relative;
  background-color: ${colors.teal1};
`;

const HeroSectionContainer = styled(Container) <{ bg: string; }>`
  display: flex;
  flex-direction: column;
  min-height: 590px;
  padding-top: ${size.xl2}px;
  padding-bottom: ${size.xl4}px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: url(${(props) => props.bg});
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 185px 0 0 0;
    z-index: 0;

    ${mediaQueries.forTabletVerticalDown} {
      position: relative;
      order: 1;
      width: 100%;
      min-height: 315px;
      border-radius: 96px 0 0 0;
    }

    ${mediaQueries.forDesktopUp} {
      width: 50%;
    }

    @media screen and (min-width: ${breakpoints.forTabletHorizontalUp}px) and (max-width: ${breakpoints.forTabletHorizontalDown}px) {
      width: 40%;
      max-width: 400px;
    }
  }

  ${mediaQueries.forTabletVerticalDown} {
    padding-right: 0;
    padding-left: 0;
    min-height: max-content;
  }
`;

const HeroNav = styled.nav`
  ${mediaQueries.forTabletVerticalDown} {
    order: 0;
    padding-right: ${size.xl1}px;
    padding-bottom: ${size.xl3}px;
    padding-left: ${size.xl1}px;
  }
`;

const HeroContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${size.xl2}px;
  max-width: 500px;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 1;

  ${mediaQueries.forTabletVerticalDown} {
    order: 2;
    width: 100%;
    max-width: max-content;
    padding-top: ${size.xl3}px;
    padding-right: ${size.xl1}px;
    padding-left: ${size.xl1}px;
    align-items: center;
  }
`;

const PartnerWelcomeContainer = styled(Container)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding-top: ${size.xl5}px;
  padding-bottom: ${size.xl5}px;
  gap: ${size.xl4}px;

  > img {
    width: auto;
    max-width: 600px;
    border-radius: 12px;
    justify-self: center;
  }

  ${mediaQueries.forTabletVerticalDown} {
    grid-template-columns: 1fr;

    > img {
      width: 100%;
    }
  }
`;
const PartnerWelcomeContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${size.xl1}px;

  h3 {
    font-weight: 500;
  }

  p {
    font-size: 21px;
    color: ${colors.gray4};
  }

  ${mediaQueries.forTabletVerticalDown} {
    align-items: center;
  }
`;

const DigitalResultsSection = styled.section`
  background-color: #f9fafb;
`;

const DigitalResultsContainer = styled(Container)`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding-top: ${size.xl3}px;
  padding-bottom: ${size.xl3}px;
  text-align: center;

  p:first-child {
    text-transform: uppercase;
    color: ${colors.green4};
    font-size: ${size.md}px;
    font-weight: 600;
    letter-spacing: 2px;
  }

  h4 {
    max-width: 300px;
    letter-spacing: normal;
    font-size: 25px;
  }
`;

const WhyEverlywellSection = styled(Container)`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: ${size.xl3}px;
  padding-bottom: ${size.xl3}px;
  gap: ${size.xl3}px;
`;

const WhyEverlywellHeadingsGroup = styled.header`
  text-align: center;
`;

const WhyEverlywellMainHeading = styled(H2)`
  font-weight: 600;
`;

const WhyEverlywellList = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${size.xl1}px ${size.xl4}px;

  ${mediaQueries.forTabletVerticalDown} {
    grid-template-columns: 1fr;
    gap: ${size.xl4}px;
  }
`;

const WhyEverlywellItem = styled.li`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  color: ${colors.gray4};
  gap: ${size.xl1}px;

  h5 {
    color: ${colors.gray5};
  }

  > img {
    width: 165px;
  }

  ${mediaQueries.forTabletVerticalDown} {
    flex-direction: column;
  }
`;

const UppercaseHeading = styled(H3)`
  text-transform: uppercase;
  color: ${colors.green4};
  font-size: ${size.md}px;
  font-weight: 600;
  letter-spacing: 2px;
`;

export {
  HeroSection,
  HeroSectionContainer,
  HeroNav,
  HeroContent,
  PartnerWelcomeContainer,
  PartnerWelcomeContent,
  DigitalResultsSection,
  DigitalResultsContainer,
  WhyEverlywellSection,
  WhyEverlywellList,
  WhyEverlywellItem,
  WhyEverlywellHeadingsGroup,
  WhyEverlywellMainHeading,
  UppercaseHeading,
};
