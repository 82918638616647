import React from 'react';

import { NavLogos } from '../../common';
import * as S from './styles';

interface Props {
  text: string;
  bg: string;
  mobileBg: string;
  partnerName: string;
  customerLogo: string;
}

const HeroSection = ({ text, bg, mobileBg, partnerName, customerLogo }: Props) => {
  return (
    <S.HeroSection>
      <S.HeroSectionContainer bg={bg} mobileBg={mobileBg}>
        <S.HeroNav>
          <NavLogos customerLogo={customerLogo} partnerName={partnerName} />
        </S.HeroNav>
        <h1>{text}</h1>
      </S.HeroSectionContainer>
    </S.HeroSection>
  );
};

export default HeroSection;
