import { colors, size, breakpoints, mediaQueries } from '@everlywell/leaves'
import styled from 'styled-components';

const SupportNav = styled.div`
  border-top: solid 1px #ddd;

  @media (min-width: ${breakpoints.forDesktopUp}px) {
    border-bottom: solid 1px #ddd;
  }
`

const SupportNavLinks = styled.ul`
  @media (min-width: ${breakpoints.forTabletVerticalUp}px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
  }

  @media (min-width: ${breakpoints.forDesktopUp}px) {
    max-width: 925px;
    margin: 0 auto;
  }

  li {
    padding: 12px 0;
    border-bottom: solid 1px #ddd;
    position: relative;

    @media (min-width: ${breakpoints.forTabletVerticalUp}px) {
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: solid 1px #ddd;
      padding: 24px 0 12px;

      :last-child {
        border-right: none;
      }
    }

    @media (min-width: ${breakpoints.forDesktopUp}px) {
      padding: 24px 0;
      border-bottom: none;
      justify-content: flex-start;
    }

    div > div {
      width: 100%;
    }
  }

  a {
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 25%;

    @media (max-width: 375px) {
      padding-left: 20%;
    }

    @media (min-width: ${breakpoints.forTabletVerticalUp}px) {
      padding-left: 0;
      flex-direction: column;
      height: 100%;
    }

    @media (min-width: ${breakpoints.forDesktopUp}px) {
      flex-direction: row;
      height: auto;
    }

    > p {
      margin: 0 0 0 24px;
      align-content: center;
      font-size: 18px;
      line-height: 1.78;
      letter-spacing: normal;
      font-weight: 400;
      color: ${colors.gray4};

      @media (min-width: ${breakpoints.forTabletVerticalUp}px) {
        margin-left: 0;
        margin-top: auto;
      }

      @media (min-width: ${breakpoints.forDesktopUp}px) {
        margin: 0;
        padding-left: ${size.lg}px;
      }
    }
  }

  img {
    &.faq-img {
      width: 32px;
      height: 30px;
    }

    &.phone-img {
      width: 32px;
      height: 24px;
    }

    &.email-img {
      width: 32px;
      height: 21px;
    }

    @media (min-width: ${breakpoints.forTabletVerticalUp}px) {
      margin-bottom: 12px;

      &.faq-img {
        height: 40px;
      }

      &.phone-img {
        width: 40px;
        height: 40px;
      }

      &.email-img {
        width: 40px;
        height: 27px;
        margin-top: 5px;
      }
    }

    @media (min-width: ${breakpoints.forDesktopUp}px) {
      margin-bottom: 0;
      margin-left: 48px;

      &.faq-img {
        width: 38px;
        height: 48px;
      }

      &.phone-img {
        width: 48px;
        height: 40px;
      }

      &.email-img {
        width: 48px;
        height: 32px;
        margin-top: 0;
      }
    }
  }
`

const FooterInfoContainer = styled.div`
  padding: 0 24px 32px;

  @media (min-width: ${breakpoints.forTabletVerticalUp}px) {
    padding: 0 56px 48px;
  }

  @media (min-width: ${breakpoints.forTabletHorizontalUp}px) {
    padding: 0 72px 48px;
  }

  @media (min-width: ${breakpoints.forDesktopUp}px) {
    max-width: 925px;
    margin: 0 auto;
    padding: 0 0 48px;
  }
`

const footerTextStyle = `
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: normal;
  font-weight: 400;
  color: ${colors.gray4};
`

const FooterNavLinks = styled.ul`
  padding: 32px 48px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 24px;
  grid-column-gap: 24px;

  @media (min-width: ${breakpoints.forTabletVerticalUp}px) {
    display: flex;
    justify-content: flex-start;
    padding: 48px 0 32px;
  }

  li {
    a {
      ${footerTextStyle}
      text-decoration: none;
    }

    @media (min-width: ${breakpoints.forTabletVerticalUp}px) {
      padding-right: ${size.lg}px;
    }
  }

  .span-all {
    grid-column-start: 1;
    grid-column-end: 3;
    text-align: center;
  }
`

const FooterInfo = styled.p`
  ${footerTextStyle}
  margin: 0 0 40px 0;

  &.no-line-break {
    margin-bottom: 0;
  }

  a {
    color: ${colors.green4};
    text-decoration: none;

    &:hover,
    &:focus {
      color: ${colors.blue1};
    }
  }

  ${mediaQueries.forPhoneOnly} {
    max-width: max-content;
  }
`

const FooterAddress = styled.address`
  ${footerTextStyle}
  margin: 0 0 40px 0;
  font-style: normal;
`

export {
  FooterNavLinks,
  FooterInfoContainer,
  FooterInfo,
  SupportNav,
  SupportNavLinks,
  FooterAddress,
}
