import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Localize } from '@everlywell/leaves';

import * as S from './styles';
import { APP_ROOT, EH_ROOT } from 'utils/constants';

const SiteFooter = () => {
  const currentYear = new Date().getFullYear();

  return (
    <S.FooterWrapper>
      <S.FooterLogoWrapper>
        <StaticImage
          alt="everlywell logo"
          src={'../../../../../assets/images/everlywellLogo.svg'}
        />
      </S.FooterLogoWrapper>
      <S.FooterContent>
        <S.Copyright>
          <S.CaptionText>
            © <Localize name="currentYear">{currentYear}</Localize> Everlywell,
            Inc. All rights reserved.
          </S.CaptionText>
        </S.Copyright>
        <S.LinksBlock>
          <S.CaptionText href={`${APP_ROOT}/privacy-policy/`} target="_blank">
            Privacy Policy
          </S.CaptionText>
          <S.CaptionText href={`${APP_ROOT}/terms-of-use/`} target="_blank">
            Terms of Use
          </S.CaptionText>
          <S.CaptionText
            href={`${EH_ROOT}/your-privacy-settings/`}
            target="_blank"
          >
            Your Privacy Choices
          </S.CaptionText>
        </S.LinksBlock>
      </S.FooterContent>
    </S.FooterWrapper>
  );
};

export default SiteFooter;
