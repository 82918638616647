import { IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react'

import collectionMethodsIcon from './images/pdp-collection-methods.svg'
import orderTestIcon from './images/pdp-order-test.svg';
import physicianReviewedIcon from './images/pdp-physician-reviewed.svg';
import receiveAtHomeIcon from './images/pdp-receive-at-home.svg';
import resultsInDaysIcon from './images/pdp-results-in-days.svg';
import sendToLabIcon from './images/pdp-send-to-lab.svg';

import * as S from './styles'

const HowItWorksSteps = [
  {
    icon: orderTestIcon,
    name: 'Order test',
  },
  {
    icon: receiveAtHomeIcon,
    name: 'Receive at home',
  },
  {
    icon: collectionMethodsIcon,
    name: 'Complete test',
  },
  {
    icon: sendToLabIcon,
    name: 'Send to lab',
  },
  {
    icon: resultsInDaysIcon,
    name: 'Receive results in just days',
  },
  {
    icon: physicianReviewedIcon,
    name: 'Share with your doctor',
  },
];

const HowItWorksSection = () => {
  return (
    <S.HowItWorksSection>
      <S.UppercaseHeading>How the process works</S.UppercaseHeading>
      <S.HowItWorksMainHeading>At-home Lab Testing Centered Around You</S.HowItWorksMainHeading>
      <S.HowItWorksListContainer>
        <S.HowItWorksList>
          {
            HowItWorksSteps.map((step, index) => (
              <S.HowItWorksItem key={index + 1}>
                <div>
                  <img src={step.icon} alt={`step icon ${index + 1}`} />
                </div>
                <p>{index + 1}. {step.name}</p>
              </S.HowItWorksItem>
            ))
          }
        </S.HowItWorksList>
      </S.HowItWorksListContainer>
    </S.HowItWorksSection>
  )
}

export default HowItWorksSection
