import React from 'react';
import { StaticImage, IGatsbyImageData } from 'gatsby-plugin-image';

import * as S from './styles';
import AccessCodeForm from 'components/forms/AccessCodeForm';

import everlywellLogo from 'assets/images/everlywell.svg';

type Props = {
  heroText: string;
  welcomeMsg: string;
  customerLogo: any;
  covidOnlyPortal: boolean;
  customerBanner: {
    desktop: IGatsbyImageData;
    mobile: IGatsbyImageData;
  };
};

const hyphenatePhrase = (phrase: string) => {
  const words = phrase?.split(' ') || [];

  return words.map((e: React.ReactNode, index: number) => (
    <React.Fragment key={`HyphenatedWord-${index}`}>
      <S.HyphenatedWord>{e}</S.HyphenatedWord>{' '}
    </React.Fragment>
  ));
};

const Introduction = (props: Props) => {
  const { heroText, welcomeMsg, customerLogo, covidOnlyPortal, customerBanner } = props;

  return (
    <S.IntroWrapper>
      <S.ImageWrapper
        covidOnlyPortal={covidOnlyPortal}
        customerBanner={customerBanner}
      >
        <S.IntroText data-isolate>{hyphenatePhrase(heroText)}</S.IntroText>
      </S.ImageWrapper>
      <S.IntroContainer>
        <S.LogoWrapper>
          {/* TODO: Replace with client logo from contentful */}
          {customerLogo ? (
            <>
              <S.ClientLogo alt="client logo" src={customerLogo} data-ignore />
              <StaticImage alt="plus icon logo" src={'../../../../../assets/images/plus-icon.svg'} />
            </>
          ) : null}

          <S.Logo alt="everlywell logo" src={everlywellLogo} />
        </S.LogoWrapper >
        <S.DetailsWrapper>
          <S.HeroText dangerouslySetInnerHTML={{ __html: welcomeMsg }} />
          <S.GetStartedWrapper>
            <S.GetStarted>Get Started</S.GetStarted>
            <S.HeroText>
              Use the invitation code provided by your organization
            </S.HeroText>
            <S.AccessCodeFormWrapper>
              <AccessCodeForm />
            </S.AccessCodeFormWrapper>
          </S.GetStartedWrapper>
        </S.DetailsWrapper>
      </S.IntroContainer >
    </S.IntroWrapper >
  );
};

export default Introduction;
