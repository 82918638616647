import React from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';

import AnalyticsPageMeta from '../../../common/AnalyticsPageMeta';
import { Layout } from '../../common';
import DigitalResultsSection from './DigitalResultsSection';
import HeroSection from './HeroSection';
import PartnerWelcomeSection from './PartnerWelcomeSection';
import WhyEverlywellSection from './WhyEverlywellSection';


interface Props {
  heroText: string;
  heroBg: {
    gatsbyImageData: IGatsbyImageData
  };
  partnerName: string;
  customerLogo: string;
  welcomeHeading: string;
  welcomeContent: string;
  whyEverlywellItems: {
    image: {
      gatsbyImageData: IGatsbyImageData
    };
    heading: string;
    subheading: string;
  }[];
  footerInfo: string;
}

const Home = ({
  heroText,
  heroBg,
  partnerName,
  customerLogo,
  welcomeHeading,
  welcomeContent,
  whyEverlywellItems,
  footerInfo,
}: Props) => {
  return (
    <Layout
      theme={{ pageBackground: '#fff' }}
      customerLogo={customerLogo}
      partnerName={partnerName}
      footerInfo={footerInfo}
      isLandingPage
    >
      <AnalyticsPageMeta pageName={'home'} />
      <HeroSection
        partnerName={partnerName}
        text={heroText}
        bg={heroBg?.gatsbyImageData?.images?.fallback?.src ?? ''}
        customerLogo={customerLogo}
      />
      <PartnerWelcomeSection heading={welcomeHeading} body={welcomeContent} />
      <DigitalResultsSection />
      <WhyEverlywellSection items={whyEverlywellItems} />
    </Layout>
  );
};

export default Home;
