import { H5, Button } from '@everlywell/leaves'
import { IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react'
import { LEARN_THE_SCIENCE_LINK } from 'utils/constants'

import * as S from './styles'

interface Props {
  items: {
    image: {
      gatsbyImageData: IGatsbyImageData
    };
    heading: string;
    subheading: string;
  }[];
}

const WhyEverlywellSection = ({ items }: Props) => {
  return (
    <S.WhyEverlywellSection>
      <S.WhyEverlywellHeadingsGroup>
        <S.UppercaseHeading>Why Everlywell</S.UppercaseHeading>
        <S.WhyEverlywellMainHeading>Where self-discovery and science meet</S.WhyEverlywellMainHeading>
      </S.WhyEverlywellHeadingsGroup>
      <S.WhyEverlywellList>
        {
          items.map((item, index) => (
            <S.WhyEverlywellItem key={index}>
              <img src={item?.image?.gatsbyImageData?.images?.fallback?.src} alt={item.heading} />
              <div>
                <H5>{item.heading}</H5>
                <p>{item.subheading}</p>
              </div>
            </S.WhyEverlywellItem>
          ))
        }
      </S.WhyEverlywellList>
      <Button href={LEARN_THE_SCIENCE_LINK} appearance="secondary" hasArrow>Learn the science</Button>
    </S.WhyEverlywellSection>
  )
}

export default WhyEverlywellSection
