import { H1, Button } from '@everlywell/leaves';
import React from 'react';
import { Link } from 'gatsby';
import { analytics } from 'utils/helpers';

import { NavLogos } from '../../common';
import * as S from './styles';

interface Props {
  text: string;
  bg: string;
  partnerName: string;
  customerLogo: string;
}

const HeroSection = ({ text, bg, partnerName, customerLogo }: Props) => {
  return (
    <S.HeroSection>
      <S.HeroSectionContainer bg={bg}>
        <S.HeroNav>
          <NavLogos customerLogo={customerLogo} partnerName={partnerName} />
        </S.HeroNav>
        <S.HeroContent>
          <H1>{text}</H1>
          <Button
            // TODO: Remove @ts-ignore after updating Leaves Button component typying.
            // @ts-ignore
            component={Link}
            to="/order"
            appearance="hero"
            onClick={() => {
              analytics.track('CTA Clicked', {
                text: 'Claim Your Tests',
              });
            }}
          >
            Claim your tests
          </Button>
        </S.HeroContent>
      </S.HeroSectionContainer>
    </S.HeroSection>
  );
};

export default HeroSection;
