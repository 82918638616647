import { Tooltip, Localize, LocalizeDisabled } from '@everlywell/leaves';
import React from 'react';
import {
  APP_ROOT,
  EF_MEMBERS_SUPPORT_LINK,
  EF_MEMBERS_SUPPORT_EMAIL,
  EF_MEMBERS_SUPPORT_PHONE_NUMBER,
  HUMANA_STATIC_FOOTER,
  HUMANA_STATIC_FOOTER_ADDRESS,
} from 'utils/constants';

import faqIcon from '../../../../assets/images/faq.svg'
import phoneIcon from '../../../../assets/images/phone.svg'
import emailIcon from '../../../../assets/images/email.svg'
import * as S from './styles';

interface Props {
  customerLogo?: string;
  partnerName: string;
  info?: string;
}

const Footer = ({ customerLogo, partnerName, info }: Props) => {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <S.SupportNav>
        <S.SupportNavLinks>
          <li>
            <a
              href={EF_MEMBERS_SUPPORT_LINK}
              title={EF_MEMBERS_SUPPORT_LINK}
              target="_blank"
              rel="noreferrer"
            >
              <span>
                <img className='faq-img' src={faqIcon} alt="FAQ Image"></img>
              </span>
              <p>Online help</p>
            </a>
          </li>
          <li>
            <Tooltip
              tooltipBoxClass="phone-tooltip"
              // @ts-ignore
              content={
                <span>
                  {EF_MEMBERS_SUPPORT_PHONE_NUMBER}{' '}
                  <LocalizeDisabled>TTY: 711</LocalizeDisabled> Available <br />
                  Monday - Friday <br />
                  <LocalizeDisabled>8AM - 8PM EST</LocalizeDisabled>
                </span>
              }
            >
              <a href={`tel:${EF_MEMBERS_SUPPORT_PHONE_NUMBER}`}>
                <span>
                  <img className='phone-img' src={phoneIcon} alt="FAQ Image"></img>
                </span>
                <p>Phone support</p>
              </a>
            </Tooltip>
          </li>
          <li>
            <a
              href={`mailto:${EF_MEMBERS_SUPPORT_EMAIL}`}
              title={EF_MEMBERS_SUPPORT_EMAIL}
            >
              <span>
                <img className='email-img' src={emailIcon} alt="FAQ Image"></img>
              </span>
              <p>Email Everlywell</p>
            </a>
          </li>
        </S.SupportNavLinks>
      </S.SupportNav>
      <S.FooterInfoContainer>
        <S.FooterNavLinks>
          <li>
            <a
              href={`${APP_ROOT}/privacy-policy/`}
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </li>
          <li>
            <a
              href={`${APP_ROOT}/terms-of-use/`}
              target="_blank"
              rel="noreferrer"
            >
              Terms of Use
            </a>
          </li>
          <li>
            <a
              href={`${APP_ROOT}/your-privacy-settings/`}
              target="_blank"
              rel="noreferrer"
            >
              Your Privacy Choices
            </a>
          </li>
          <li className="span-all">
            <a
              href={`${APP_ROOT}/members/consentandnotice/`}
              target="_blank"
              rel="noreferrer"
            >
              Informed Consent and Notice of Privacy Practices
            </a>
          </li>
        </S.FooterNavLinks>
        <S.FooterInfo>
          Copyright © <Localize name="current-year">{currentYear}</Localize> Everlywell,
          Inc. All rights reserved.
        </S.FooterInfo>
        <S.FooterInfo className='no-line-break'>{HUMANA_STATIC_FOOTER.FOOTER_TEXT}</S.FooterInfo>
        <S.FooterInfo>{HUMANA_STATIC_FOOTER.FOOTER_DETAILS}</S.FooterInfo>
        <S.FooterInfo className='no-line-break'>{HUMANA_STATIC_FOOTER.FOOTER_IMPORTANT_DISCLAIMER}</S.FooterInfo>
        <S.FooterInfo className='no-line-break'>{HUMANA_STATIC_FOOTER.FOOTER_ENGLISH}</S.FooterInfo>
        <S.FooterInfo className='no-line-break'>{HUMANA_STATIC_FOOTER.FOOTER_SPANISH}</S.FooterInfo>
        <S.FooterInfo >{HUMANA_STATIC_FOOTER.FOOTER_MANDARIN}</S.FooterInfo>
        <S.FooterInfo>
          <div dangerouslySetInnerHTML={{ __html: info ?? '' }} />
        </S.FooterInfo>
        <S.FooterAddress>
          <S.FooterInfo>
            {HUMANA_STATIC_FOOTER_ADDRESS.COMPANY_NAME}<br />
            {HUMANA_STATIC_FOOTER_ADDRESS.COMPANY_ADDRESS}<br />
            {HUMANA_STATIC_FOOTER_ADDRESS.COMPANY_ADDRESS_SEC_LINE}<br />
          </S.FooterInfo>
        </S.FooterAddress>
        <S.FooterInfo className='no-line-break'>
          {HUMANA_STATIC_FOOTER_ADDRESS.FOOTER_ID}<br />
          {HUMANA_STATIC_FOOTER_ADDRESS.LAST_UPDATED}
        </S.FooterInfo>
      </S.FooterInfoContainer>
    </footer>
  );
};

export default Footer;
