import { H4, Button } from '@everlywell/leaves';
import React from 'react';
import { EF_MEMBERS_LINK } from 'utils/constants';
import { analytics } from 'utils/helpers';

import * as S from './styles';

const DigitalResultsSection = () => {
  return (
    <S.DigitalResultsSection>
      <S.DigitalResultsContainer>
        <S.UppercaseHeading>Digital Results</S.UppercaseHeading>
        <H4>Easy to understand results on any device</H4>
        <Button
          href={EF_MEMBERS_LINK}
          appearance="secondary"
          onClick={() => {
            analytics.track('CTA Clicked', {
              text: 'View My Results',
            });
          }}
        >
          View my results
        </Button>
      </S.DigitalResultsContainer>
    </S.DigitalResultsSection>
  );
};

export default DigitalResultsSection;
