import accessCodeValidator from 'api/access-code-validator';
import { navigate } from 'gatsby';
import React, { useState } from 'react';
import { analytics, trim } from 'utils/helpers';
import { authorize, setInnerNavigate } from 'utils/services/auth';

import AccessCodeField from './AccessCodeField';
import AccessCodeSubmit from './AccessCodeSubmit';

const SUCCESS_URL = '/app/wizard';
const SUCCESS_URL_TEST_TAKER_PAID = '/app/payment-steps/personal-info';

const INVALID_ERROR_MESSAGE = 'Please enter valid access code';
const UNAVAILABLE_ERROR_MESSAGE = 'This code is no longer valid';

const AccessCodeForm = () => {
  const [code, updateCode] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const accessCode = event.currentTarget.value;

    updateCode((_) => {
      // removes error message when editing
      !!error && setError('');

      return trim(accessCode?.toUpperCase());
    });
  };

  const checkAccessCode = () => {
    setIsLoading(true);

    accessCodeValidator(code).then(
      ({
        data: {
          isValid,
          isAvailable,
          product_ids,
          contentful_product_ids,
          contentful_product_id_to_price,
          unique_id_required,
          unique_id_format,
          unique_id_label,
          enterprise_client_id,
          shipping_methods,
          test_taker_paid,
          disabled_states,
          product_ids_to_minimum_age,
          tenant_name,
          unique_id_validation_enabled,
          auto_register_orders,
          member_id_tooltip,
          email_required,
          phone_required,
        },
      }) => {
        setIsLoading(false);
        if (isValid && isAvailable) {
          authorize({ accessCode: code });
          setInnerNavigate();
          const url = test_taker_paid
            ? SUCCESS_URL_TEST_TAKER_PAID
            : SUCCESS_URL;

          navigate(url, {
            state: {
              productIds: product_ids,
              contentfulProductIds: contentful_product_ids,
              contentfulProductIdToPrice: contentful_product_id_to_price,
              shippingMethods: shipping_methods,
              enterpriseClientId: enterprise_client_id,
              uniqueId: {
                required: unique_id_required,
                format: unique_id_format,
                label: unique_id_label,
                tooltip: member_id_tooltip,
              },
              disabledStates: disabled_states,
              productIdsToMinimumAge: product_ids_to_minimum_age,
              lineItems: product_ids.map((id: number) => ({
                product_id: id,
                quantity: 1,
              })),
              tenantName: tenant_name,
              uniqueIdValidationEnabled: unique_id_validation_enabled,
              /*
                To enable auto register orders
                we also need to verify the unique id existence.
              */
              autoRegisterOrders:
                auto_register_orders && unique_id_validation_enabled,
              configValues: {
                email_required: email_required,
                phone_required: phone_required,
              },
            },
          });

          analytics.track('CTA Clicked', {
            access_code: code,
            text: 'Order your test successfully',
          });
        }

        if (!isValid) {
          analytics.track('CTA Clicked', {
            access_code: code,
            error_message: INVALID_ERROR_MESSAGE,
            text: 'Order your test unsuccessfully',
          });

          setError(INVALID_ERROR_MESSAGE);
        } else if (!isAvailable) {
          analytics.track('CTA Clicked', {
            access_code: code,
            error_message: UNAVAILABLE_ERROR_MESSAGE,
            text: 'Order your test unsuccessfully',
          });
          setError(UNAVAILABLE_ERROR_MESSAGE);
        }
      },
    );
  };

  return (
    <>
      <AccessCodeField value={code} onChange={onChange} error={error} />
      <AccessCodeSubmit
        onClick={checkAccessCode}
        isDisabled={!code}
        isLoading={isLoading}
      />
    </>
  );
};
export default AccessCodeForm;
